import "./page-tile.scss";
import React from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";

const PageTile = ({ title, url, thumbnail, color }) => {
  return (
    <Link to={url}>
      <div className="page-tile">
        <LazyLoad>
          <div
            className="page-tile__thumbnail"
            style={{ backgroundImage: `url(${thumbnail})` }}
          />
        </LazyLoad>
        <div
          className="page-tile__title-container"
          style={{ backgroundColor: color }}
        >
          <div className="page-tile__title">{title}</div>
          <img
            src={require("assets/icons/arrow-right-big.svg").default}
            alt=""
          />
        </div>
      </div>
    </Link>
  );
};

export default PageTile;

import "./news-tile-small.scss";

import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";

const NewsTileSmall = ({ thumbnail, title, description, link, newest }) => {
  return (
    <Link
      to={link}
      className={classNames(`news-tile-small`, {
        [`news-tile-small--newest`]: newest,
      })}
    >
      <LazyLoad>
        <div
          className="news-tile-small__thumbnail"
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
      </LazyLoad>
      <div className="news-tile-small__right-content">
        <h3 className="news-tile-small__title">{title}</h3>
        <div
          className="news-tile-small__text"
          dangerouslySetInnerHTML={{
            __html: description.substring(0, 85) + "...",
          }}
        />
        <span className="arrow-link">Czytaj dalej</span>
      </div>
    </Link>
  );
};

export default NewsTileSmall;

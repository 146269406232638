import "./booking-modal.scss";

import React from "react";

const BookingModal = ({ setBookingModal }) => {
  return (
    <div className="booking-modal">
      <div className="booking-modal__box">
        <div className="booking-modal__top">
          <button
            type="button"
            className="booking-modal__close-btn"
            onClick={() => setBookingModal(false)}
          >
            <img src={require("assets/icons/x-mark.svg").default} alt="" />
          </button>
        </div>
        <div className="booking-modal__content">
          <div className="booking-modal__left">
            <img src={require("assets/images/modal1.jpg").default} alt="" />
            <p className="booking-modal__title">Poradnia w Wołominie</p>
            <a
              href="tel:0048501775717"
              className="booking-modal__btn booking-modal__btn--red"
            >
              Zadzwoń 501 775 717
            </a>
            <a
              href="tel:721642466"
              className="booking-modal__btn booking-modal__btn--red"
            >
              Zadzwoń 721 642 466
            </a>
          </div>
          <div className="booking-modal__right">
            <img src={require("assets/images/modal2.jpg").default} alt="" />
            <p className="booking-modal__title">Poradnia Warszawa Białołęka</p>
            <a
              href="tel:0048725396700"
              className="booking-modal__btn booking-modal__btn--red"
            >
              Zadzwoń 725 396 700
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;

import "./person-tile.scss";

import React from "react";
import { Link } from "gatsby";

const PersonTile = ({
  name,
  position,
  secondPosition,
  img,
  url,
  text,
  location,
}) => {
  const wolomin = location?.includes("wolomin");
  const bialoleka = location?.includes("bialoleka");
  const online = location?.includes("online");

  return (
    <div className="person-tile">
      <div
        className="person-tile__image"
        style={{ backgroundImage: `url(${img})` }}
      />
      <div className="person-tile__hidden-content">
        <div className="person-tile__name">{name}</div>
        <div className="person-tile__position">{position}</div>
        {text && <div className="person-tile__text">{text}</div>}
        <div className="person-tile__second-position">{secondPosition}</div>
        <div className="person-tile__location">
          {wolomin || bialoleka ? (
            <div className="person-tile__location-row">
              <img src={require("assets/icons/pin-white.svg").default} alt="" />
              <p>
                Poradnia: {wolomin && "Wołomin"} {wolomin && bialoleka && " / "}{" "}
                {bialoleka && "Białołęka"}
              </p>
            </div>
          ) : null}
          <div className="person-tile__location-row">
            <img src={require("assets/icons/coffee.svg").default} alt="" />
            <p>
              {wolomin || bialoleka ? "Stacjonarnie" : null}
              {(wolomin || bialoleka) && online ? " / " : null}{" "}
              {online && "Online"}
            </p>
          </div>
        </div>
        <Link
          to={`/zespol${url}`}
          className="button button--green button--small"
        >
          Zobacz profil
        </Link>
      </div>
    </div>
  );
};

export default PersonTile;

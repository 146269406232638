import "styles/pages/page-text.scss";
import React, { useState } from "react";
import { graphql, Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import PersonTile from "components/PersonTile";
import Divider from "components/Divider";
import PageTile from "components/PageTile";
import Breadcrumps from "components/Breadcrumps";
import ReviewSection from "../components/ReviewSection";
import HomeBlogSection from "../components/HomeBlogSection";
import BookingModal from "../components/BookingModal";

const renderPageData = (item) => {
  switch (item?.fieldGroupName) {
    case "ContentNode_Textpage_Content_TextField":
      return (
        <div
          className="text-page__text-container"
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      );
    case "ContentNode_Textpage_Content_Team":
      return (
        <div className="text-page__team-container team">
          <div className="row">
            {item?.persons?.map((person) => (
              <div className="col-lg-3 col-md-4 col-sm-6" key={person.id}>
                <PersonTile
                  name={person.team.fullName}
                  text={person.team.tileDesc}
                  position={person.team.position}
                  secondPosition={person.team.secondPosition}
                  url={person.uri}
                  img={person.team.img?.sourceUrl}
                />
              </div>
            ))}
          </div>
        </div>
      );
    case "ContentNode_Textpage_Content_BigImg":
      return (
        <div className="text-page__big-img-container">
          <img src={item.img?.sourceUrl} alt="" className="img-fluid" />
        </div>
      );
    case "ContentNode_Textpage_Content_PageTiles":
      return (
        <div className="text-page__tiles-container">
          <div className="row">
            {item.tiles?.map((tile, index) => (
              <div className="col-md-4" key={index}>
                <PageTile
                  title={tile.title}
                  url={tile.link}
                  color={tile.color}
                  thumbnail={tile.img?.sourceUrl}
                />
              </div>
            ))}
          </div>
        </div>
      );
    case "ContentNode_Textpage_Content_ColumnsLinks":
      return (
        <ul className="text-page__links-container">
          {item.links?.map((link, index) => (
            <li className="text-page__single-link" key={index}>
              <a href={link.url}>{link.label}</a>
            </li>
          ))}
        </ul>
      );
    case "ContentNode_Textpage_Content_Reviews":
      return <ReviewSection />;
    case "ContentNode_Textpage_Content_Blog":
      return <HomeBlogSection />;
    default:
      return null;
  }
};

const TextPage = ({ data }) => {
  const [bookingModal, setBookingModal] = useState(false);
  const pageData = data.wpPage.textPage.content;
  const seo = data.wpPage.seo;

  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        image={seo?.opengraphImage?.localFile?.publicURL}
      />
      <Breadcrumps data={data.wpPage.title} />
      <SubpageHeader smallMargin title={data.wpPage.title} />
      <section className="text-page">
        <div className="container">
          {pageData?.map((item, index) => (
            <div key={index}>{renderPageData(item)}</div>
          ))}
          <Divider marginTop={45} marginBottom={45} />
          <div className="team-visit__buttons">
            <Link to="/rezerwacja-wizyt/" className="button button--green">
              Umów wizytę
            </Link>
            <button
              onClick={() => setBookingModal(true)}
              className="button button--red"
            >
              Zadzwoń
            </button>
            <Link to="/kontakt/" className="button button--orange">
              Wyślij zapytanie
            </Link>
          </div>
        </div>
      </section>
      {bookingModal && <BookingModal setBookingModal={setBookingModal} />}
    </Layout>
  );
};

export default TextPage;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      textPage {
        content {
          ... on WpContentNode_Textpage_Content_Team {
            fieldGroupName
            persons {
              ... on WpZespol {
                id
                uri
                team {
                  tileDesc
                  secondPosition
                  position
                  img {
                    sourceUrl
                  }
                  fullName
                }
              }
            }
          }
          ... on WpContentNode_Textpage_Content_ColumnsLinks {
            fieldGroupName
            links {
              label
              url
            }
          }
          ... on WpContentNode_Textpage_Content_TextField {
            content
            fieldGroupName
          }
          ... on WpContentNode_Textpage_Content_PageTiles {
            fieldGroupName
            tiles {
              color
              link
              title
              img {
                sourceUrl
              }
            }
          }
          ... on WpContentNode_Textpage_Content_BigImg {
            fieldGroupName
            img {
              sourceUrl
            }
          }
          ... on WpContentNode_Textpage_Content_Reviews {
            fieldGroupName
          }
          ... on WpContentNode_Textpage_Content_Blog {
            fieldGroupName
          }
        }
      }
      id
      title
      uri
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Divider from "components/Divider";
import NewsTileSmall from "components/NewsTile/Small";
import ArticleBanner from "components/ArticleBanner";

import { usePosts } from "context/posts";

const HomeBlogSection = () => {
  const pageData = useStaticQuery(
    graphql`
      query lastArticles {
        allWpPage(filter: { title: { eq: "Home" } }) {
          nodes {
            pageHome {
              blogTitle
              blogBannerText
              blogBannerImg {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    `
  );

  const { posts } = usePosts();

  return (
    <section className="home-last-news">
      <div className="container">
        <h2 className="home-last-news__title">
          Przewodnik dla pacjenta: najnowsze publikacje
        </h2>
        <Divider marginTop={15} marginBottom={50} />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="home-last-news__subtitle">Ostatnie wpisy</h3>
            {posts
              ?.filter((item) =>
                item?.categories?.nodes?.some(
                  (cat) => cat.slug !== "artykuly-partnerow"
                )
              )
              .slice(0, 2)
              ?.map((item, index) => (
                <div key={index}>
                  <NewsTileSmall
                    newest={index === 0}
                    title={item.title}
                    description={item.excerpt}
                    thumbnail={item.featuredImage?.node.sourceUrl}
                    link={item.uri}
                  />
                  {index === 0 && <Divider marginBottom={30} marginTop={30} />}
                </div>
              ))}
          </div>
          <div className="col-lg-6">
            <ArticleBanner
              articleBanner={pageData?.allWpPage?.nodes[0]?.pageHome}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBlogSection;

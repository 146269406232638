import "./article-banner.scss";

import React from "react";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";

const ArticleBanner = ({ articleBanner }) => {
  const backgroundImage = {
    backgroundImage: `url(${articleBanner?.blogBannerImg?.sourceUrl})`,
  };

  return (
    <div className="article-banner">
      <LazyLoad>
        <div className="article-banner__image" style={backgroundImage} />
      </LazyLoad>
      <div className="article-banner__text-container">
        <div
          className="article-banner__title"
          dangerouslySetInnerHTML={{ __html: articleBanner.blogBannerText }}
        />
        <Link to="/blog/" className="button button--green">
          Zobacz wszystkie artykuły
        </Link>
      </div>
    </div>
  );
};

export default ArticleBanner;
